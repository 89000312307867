<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="who we are"/>
        <main>
            <section>
                <div class="organizing-partners">
                    <div class="container">
                        <div v-for="(p, index) in newPartners" :key="'new_partner'+index" class="item">
                            <div class="logo">
                                <picture> <img :src="logourl(p)" alt=""> </picture>
                            </div>
                            <div class="text">  
                                <h2> {{ p.attributes.title }} </h2>
                                <p v-html="p.attributes.field_description.value"> </p>
                            </div>
                        </div>

                        <div v-if="this.pageData.field_ref_ui_text.length" class="text-secretariat">
                            <h3 v-html="this.pageData.field_ref_ui_text[0].attributes.field_uitext_title.value" id="secretariat"></h3>
                            <p v-html="this.pageData.field_ref_ui_text[0].attributes.field_description_w_summary.value"></p>
                        </div>

                        <h3 class="old-label">Until 2021, Science in Exile was supported by:</h3>

                        <div v-for="(p, index) in oldPartners" :key="'old_partner'+index" class="item item-old">
                            <div class="logo">
                                <picture> <img :src="logourl(p)" alt=""> </picture>
                            </div>
                            <div class="text">
                                <h2> {{ p.attributes.title }} </h2>
                                <p v-html="p.attributes.field_description.value"> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    </div>
</template>
<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import { fetchNodes, fetchSinglePage } from '../libs/drupalClient'
    export default {
        name: 'organizing-partners',
        components: {
            HeaderIntroImage
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "263c024c-a05a-4a0c-a428-737b1febd2ec",
                partnerData: []
            }
        },
        computed: {
            oldPartners() {
                return this.partnerData.filter(p => !p.attributes.field_partner_on_going)
            },
            newPartners() {
                //reorder by ongoing status
                let p = this.partnerData.filter(p => p.attributes.field_partner_on_going);

                // remove isc and push it last
                let partnerIsc = p.splice(1,1)
                p.push(partnerIsc[0])
                //console.log('p ', p);

                return p
            },
            hash() {
                return this.$route.hash.toLowerCase()
            }
        },
        methods: {
            logourl(p) {
                if(p.field_image)
                    return process.env.VUE_APP_ENDPOINT + p.field_image.attributes.uri.url
                else
                    return ''
            }
        },
        async mounted() {
            await fetchSinglePage(this.currentPageID, {
                include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text']
            })
            .then(res => {
                this.pageData = res
                //console.log('pagedata', res)
            })

            await fetchNodes('partner', {
                include: ['field_image']
            }).then(res => {
                this.partnerData = res
                //console.log('all partners', res)
            })

            await setTimeout(() => {
                if (this.hash && document.querySelector(this.hash)) {
                    window.scrollBy({
                        top: document.querySelector(this.hash).getBoundingClientRect().top - 200,
                        behavior: 'smooth'
                    })
                }
            }, 200);
        },

    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

    div.item {
        margin-top: -3rem;
        margin-bottom: 5rem;
        position: relative;
        z-index: 3;
    }

    .text-secretariat {
        margin: -1rem 0rem 6rem 0rem;
        padding: 2rem 2rem;
        background: #F1852F;
        
        h3 {
            line-height: 2rem;
            color: #fff;
        }
        
        ::v-deep p {
            color: rgb(255, 255, 255);
            a {
                color: #fff;
                text-decoration: underline;
                transition: 0.2s ease;
            }
            a:hover {
                transition: 0.2s ease;
                opacity: 0.8;
            }
        }
        
        p ::v-deep li:before {
            border: 2px solid #ffffff;
        }
    }

    .item-old {
        background-color: #f5f9fd00;
        box-shadow: none;
        border: 4px solid #fff;
    }

    .old-label {
        margin-bottom: 4rem;
        color: $green;
        font-size: 18px;
    }
</style>